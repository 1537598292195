import React, { useState } from 'react';
import { User } from '@5minds/processcube_authority_sdk';
import { ArrowDownTrayIcon, ArrowPathIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '../../../components';
import { parseUserToPrettyString, sortByUserName } from '../../../infrastructure';

type UsersTableProps = {
  users: Array<User>;
  currentlyLoggedInUser: User;
  routerPrefix: string;
  setSelectedUser: (user: User) => void;
  setShowDelete: (show: boolean) => void;
};

export function UsersTable(props: UsersTableProps): JSX.Element {
  const [users, setUsers] = useState<Array<User>>([...props.users, props.currentlyLoggedInUser]);
  const [selectedUsers, setSelectedUsers] = useState<Array<User>>(users);

  return (
    <div className="mt-4 flex flex-col items-center">
      <div className="max-w-3xl w-full">
        <div className="flex w-full pb-2 px-2 justify-between">
          <h2 className="text-xl text-gray-900 font-semibold">Accounts</h2>
          <div className="flex gap-4">
            <CreateButton routerPrefix={props.routerPrefix} />
            <ExportButton users={selectedUsers} />
            <RefreshButton />
          </div>
        </div>
        <div className="ring-black/5 relative overflow-hidden shadow ring-1 md:rounded-lg">
          <table className="table-fixed w-full divide-gray-300 divide-y">
            <thead className="w-full bg-gray-50">
              <tr className="w-full">
                <th className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold w-12">
                  <input
                    aria-describedby="all-users-selected"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-5minds-blue-800 focus:ring-5minds-blue-800"
                    checked={selectedUsers.length === props.users.length + 1}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedUsers(users);
                      } else {
                        setSelectedUsers([]);
                      }
                    }}
                  />
                </th>
                <th scope="col" className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold w-44">
                  Account-ID
                </th>
                <th scope="col" className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold max-w-md">
                  Username
                </th>
                <th scope="col" className="flex justify-end py-4 px-4">
                  <span className="sr-only">Options</span>
                </th>
              </tr>
            </thead>
            <tbody className="w-full divide-gray-200 bg-white divide-y">
              {users
                .sort(sortByUserName)
                .sort((a, b) => (a.isDeleted ? 1 : 0) - (b.isDeleted ? 1 : 0))
                .map((user) => (
                  <UserRow
                    key={user.accountId}
                    user={user}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    setSelectedUser={props.setSelectedUser}
                    setShowDelete={props.setShowDelete}
                    routerPrefix={props.routerPrefix}
                    deleteDisabled={user.accountId === props.currentlyLoggedInUser.accountId}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function UserRow(props: {
  user: User;
  selectedUsers: Array<User>;
  routerPrefix: string;
  deleteDisabled: boolean;
  setSelectedUsers: React.Dispatch<React.SetStateAction<User[]>>;
  setSelectedUser: (user: User) => void;
  setShowDelete: (show: boolean) => void;
}): JSX.Element {
  return (
    <tr key={props.user.accountId} className="w-full">
      <td className="w-12 px-3 py-4">
        <input
          aria-describedby="user-selected"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-5minds-blue-800 focus:ring-5minds-blue-800"
          checked={props.selectedUsers.some((selectedUser) => selectedUser.accountId === props.user.accountId)}
          onChange={(event) => {
            if (event.target.checked) {
              props.setSelectedUsers((selectedUsers) => [...selectedUsers, props.user]);
            } else {
              props.setSelectedUsers((selectedUsers) =>
                selectedUsers.filter((selectedUser) => selectedUser.accountId !== props.user.accountId)
              );
            }
          }}
        />
      </td>
      <td
        className={`truncate text-gray-600 px-3 py-4 text-sm ${props.user.isDeleted ? 'opacity-50' : ''}`}
        title={props.user.accountId}
      >
        {props.user.accountId}
      </td>
      <td className={`truncate text-gray-600 px-3 py-4 text-sm ${props.user.isDeleted ? 'opacity-50' : ''}`}>
        {props.user.username}
      </td>
      <td className="flex gap-4 py-4 px-4 justify-end text-right text-sm font-medium">
        <EditButton user={props.user} routerPrefix={props.routerPrefix} />
        <DeleteButton
          user={props.user}
          setShowDelete={props.setShowDelete}
          setSelectedUser={props.setSelectedUser}
          deleteDisabled={props.deleteDisabled}
        />
      </td>
    </tr>
  );
}

function HeaderButton(props: {
  icon: JSX.Element;
  text: string;
  title?: string;
  disabled?: boolean;
  onClick?: () => void;
}) {
  return (
    <button
      type="button"
      className={`relative inline-flex items-center gap-x-1.5 rounded-md p-1 text-sm font-semibold hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-5minds-blue-800 ${
        props.disabled ? 'text-gray-400' : 'text-5minds-blue-800'
      }`}
      title={props.title ?? props.text}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon}
      {props.text}
    </button>
  );
}

function CreateButton(props: { routerPrefix: string }) {
  return (
    <a className="relative inline-flex items-center" href={`${props.routerPrefix}/admin/user/create`}>
      <HeaderButton
        icon={<PlusIcon className="h-5 w-5" aria-hidden="true" title="Create User" />}
        text="Create"
        title="Create New Account"
      />
    </a>
  );
}

function ExportButton(props: { users: User[] }) {
  function formatUserForExport(user: User) {
    const scopes: string[] = user.scopes.map((scope) => scope.name);
    const claims: Record<string, any> = user.claims.reduce((acc, claim) => {
      if (claim.value != null) {
        acc[claim.name] = claim.value;
      }

      return acc;
    }, {} as Record<string, any>);

    return {
      username: user.username,
      password: '',
      scopes,
      claims,
    };
  }

  return (
    <HeaderButton
      icon={<ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" title="Export As JSON" />}
      text="Export"
      title="Export As JSON"
      disabled={props.users.length === 0}
      onClick={() => {
        const users = props.users.map((user) => formatUserForExport(user));
        const data = JSON.stringify({ users }, null, 2);
        const blob = new Blob([data], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'users.json';
        link.href = url;
        link.click();
      }}
    />
  );
}

function RefreshButton() {
  return (
    <HeaderButton
      icon={<ArrowPathIcon className="h-5 w-5" aria-hidden="true" title="Refresh Table" />}
      text="Refresh"
      title="Refresh Table"
      onClick={() => window.location.reload()}
    />
  );
}

function EditButton({ user, routerPrefix }: { user: User; routerPrefix: string }): JSX.Element | null {
  if (user.isDeleted) {
    return null;
  }

  return (
    <a
      href={`${routerPrefix}/admin/user/${user.username}/edit`}
      className="text-5minds-blue-800 hover:text-5minds-blue-900 hover:cursor-pointer"
    >
      <PencilSquareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" title="Edit" />
      <span className="sr-only">, Edit {user.username}</span>
    </a>
  );
}

function DeleteButton(props: {
  user: User;
  deleteDisabled: boolean;
  setShowDelete: (show: boolean) => void;
  setSelectedUser: (user: User) => void;
}): JSX.Element {
  if (props.user.isDeleted) {
    return <div className="text-red-700">Deleted</div>;
  }

  if (props.deleteDisabled) {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
      <Tooltip message={<p>You can't delete your account.</p>} show={showTooltip} setShow={setShowTooltip}>
        <a className="text-gray-300">
          <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" title="Delete" />
          <span className="sr-only">, Delete {props.user.username}</span>
        </a>
      </Tooltip>
    );
  }

  return (
    <a
      className="text-red-700 hover:text-red-800 hover:cursor-pointer"
      onClick={() => {
        props.setSelectedUser(props.user);
        props.setShowDelete(true);
      }}
    >
      <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" title="Delete" />
      <span className="sr-only">, Delete {props.user.username}</span>
    </a>
  );
}
