import React, { useState } from 'react';

import { ErrorNotification, WithDefaultNavBar } from '../../../components';

import { DeleteUserDialog } from './DeleteUserDialog';
import { UserDetailsDialog } from './UserDetailsDialog';
import { UsersTable } from './UsersTable';
import { User } from '@5minds/processcube_authority_sdk';

interface DashboardProps {
  routerPrefix: string;
  currentlyLoggedInAccountId: string;
  logo: string;
  issuerUrl: string;
  users?: Array<User>;
}

export function Dashboard(props: DashboardProps): JSX.Element {
  const currentlyLoggedInUser = props.users?.find((user) => user.accountId === props.currentlyLoggedInAccountId);
  const [users, setUsers] = useState<Array<User>>(
    props.users?.filter((user) => user.accountId !== currentlyLoggedInUser?.accountId) ?? []
  );
  const [selectedUser, setSelectedUser] = useState<User>(currentlyLoggedInUser!);
  const [showDelete, setShowDelete] = useState(false);
  const [error, setError] = useState<string | null>(null);

  function deleteUser(username: string): void {
    fetch(`${props.routerPrefix}/admin/user/${username}/delete`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fullDelete: false }),
    })
      .then(async (response) => {
        if (!response.ok) throw new Error(await response.text());
        return response.json();
      })
      .then((deletedUser: User) => {
        const updatedUser = users.find((user) => user.accountId === deletedUser.accountId);
        if (!updatedUser) {
          return;
        }

        updatedUser.isDeleted = true;
        setUsers((users) => [...users.filter((user) => user.accountId !== deletedUser.accountId), updatedUser]);
        setShowDelete(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  }

  return (
    <>
      <ErrorNotification message={error} setMessage={setError} />

      <DeleteUserDialog
        routerPrefix={props.routerPrefix}
        user={selectedUser}
        show={showDelete}
        deleteUser={deleteUser}
        hide={() => setShowDelete(false)}
      />

      <WithDefaultNavBar issuerUrl={props.issuerUrl} logo={props.logo} routerPrefix={props.routerPrefix}>
        <UsersTable
          users={users}
          currentlyLoggedInUser={currentlyLoggedInUser!}
          routerPrefix={props.routerPrefix}
          setSelectedUser={setSelectedUser}
          setShowDelete={setShowDelete}
        />
      </WithDefaultNavBar>
    </>
  );
}
